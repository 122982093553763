import React, { useEffect } from 'react';
import '../Home/home.css';
import Header from '../../Components/Header/Header';
import Carousel from '../../Components/Carousel/Carousel';
import prod1 from '../../images/products/prod-1.jpg';
import prod2 from '../../images/products/prod-2.jpg';
import prod3 from '../../images/products/prod-3.jpg';
import Footer from '../../Components/Footer/Footer';
import ScrollToTopButton from '../../Components/Scrolltotop/Scrolltotop';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ref1 from '../../images/reference1.jpg';
import ref2 from '../../images/reference2.jpg';
import { Helmet } from 'react-helmet';

function Home() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.targetId) {
            scrollToElement(location.state.targetId);
        }
    }, [location]);

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            console.log(`Found element with ID ${id}. Scrolling to it...`);
            const navbarHeight = document.querySelector('nav').offsetHeight;
            const offset = 10;
            window.scrollTo({
                top: element.offsetTop - navbarHeight - offset,
                behavior: 'smooth',
            });
        } else {
            console.warn(`Element with ID ${id} not found.`);
        }
    };

    const navigateToAirDistribution = (id) => {
        if (location.pathname === '/air_distribution') {
            handleScroll(id);
        } else {
            navigate('/air_distribution', { state: { targetId: id } });
            const observer = new MutationObserver(() => {
                if (document.getElementById(id)) {
                    handleScroll(id);
                    observer.disconnect();
                }
            });
            observer.observe(document.body, { childList: true, subtree: true });
        }
    };

    const handleScroll = (id) => {
        console.log(`Handling scroll to element with ID: ${id}`);
        const element = document.getElementById(id);
        if (element) {
            const navbarHeight = document.querySelector('nav').offsetHeight;
            const offset = 10;
            window.scrollTo({
                top: element.offsetTop - navbarHeight - offset,
                behavior: 'smooth',
            });
        } else {
            console.warn(`Element with ID ${id} not found.`);
        }
    };

    return (
        <>
            <Helmet>
                <title>HEPA Filters BSL-3 | BSL-4 | Biosafety Lab Ventilation</title>
                <meta name="description" content=" " />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="Dubai" />
                <meta http-equiv="content-language" content="ae" />
                <meta name="geo.region" content="Dubai" />
                <meta property="og:title" content="HEPA Filters BSL-3 | BSL-4 | Biosafety Lab Ventilation" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
                <meta property="og:locale" content="en-ae" />
                <link rel="alternate" href="https://www.scientechnology.com" hreflang="en-ae" />
                <link rel="canonical" href="https://www.scientechnology.com" />
            </Helmet>
            <section id='home-content'>
                <Header />
                <Carousel />
                <div className='container center-content main_container'>
                    <div className='col-md-12 text-center corona_title'>
                        <h3>"<span className='covid'>Fight against COVID-19:</span> High integrity products for Isolation Wards & Laboratories"</h3>
                        <Link to="/highintegrity" target='_blank' rel="noopener noreferrer"><button className='prct_btn mt-2'>Product Details</button></Link>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 className='title'>about</h3>
                            <hr className='hr' />
                        </div>
                        <div className='col-md-6 about_head'>
                            <h4>Who We Are?</h4>
                            <p>Scientific Technology Systems offers a wide range of quality and state-of-the art Ventilation special solutions to the most sensitive sectors, such as the Bio Safety Labs, Nuclear industry, High-security technologies and Critical air flow controls for labs and fume hoods. We are the partners for Krantz GmbH, Germany who is specialized in Air Distribution systems for more than 100 years.</p>
                            <Link to="/about_profile"><button className='more_btn'>More</button></Link>
                        </div>
                        <div className='col-md-6 about_head'>
                            <h4 className='asi_part'>We are part of Acoustical Surfaces</h4>
                            <p>We are a division of Acoustical Surfaces Pvt Ltd, which is a multi-disciplined contracting company specialized in Acoustical Solutions and similar engineering products. We have our presence in the Middle East through our Group company, MOOV Group. We are focused on the satisfaction of clients backed by quality and commitment to their services.</p>
                            <Link to="https://www.acousticalsurfaces.in/" target='blank'><button className='prct_btn'>Visit Acoustical Surfaces</button></Link>
                        </div>
                    </div>

                    <div className='col-md-12 mt-5'>
                        <h3 className='title'>products</h3>
                        <hr style={{ marginTop: "1em" }} />
                        <div className="col-md-12 mt-5 product">
                            <div className="product-content">
                                <div className="product-image-container">
                                    <img src={prod1} className="img-fluid product-image" alt="product_1" />
                                    <div className="overlay-content">
                                        <h4 className='content-title'>Air Distribution Systems</h4>
                                        <Link to="/air_distribution"><button className="view-products-btn">View Products</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-5 product">
                            <div className="product-content">
                                <div className="product-image-container">
                                    <img src={prod2} className="img-fluid product-image" alt="product_2" />
                                    <div className="overlay-content">
                                        <h4 className='content-title'>Cooling and Heating Systems</h4>
                                        <button className="view-products-btn" onClick={() => navigateToAirDistribution('cooling-heating')}>View Products</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-5 product">
                            <div className="product-content">
                                <div className="product-image-container">
                                    <img src={prod3} className="img-fluid product-image" alt="product_3" />
                                    <div className="overlay-content">
                                        <h4 className='content-title'>Filter and Damper Systems</h4>
                                        <button className="view-products-btn" onClick={() => navigateToAirDistribution('filter-damper')}>View Products</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 mt-5'>
                        <h3 className='title'>REFERENCE</h3>
                        <hr style={{ marginTop: "1em" }} />
                        <div className='row'>
                            <div className='col-md-4 col-sm-6'>
                                <img className='img-fluid' src={ref1} alt="reference" />
                            </div>
                            <div className='col-md-8 col-sm-6'>
                                <img className='img-fluid ref_img' src={ref2} alt="reference" />
                            </div>
                            <h6 className='refrence_content'>International Centre for Foot and Mouth Disease - Bhubaneswar,India</h6>
                        </div>
                    </div>
                    <hr style={{ marginTop: "4em" }} />
                </div>
            </section>
            <Footer />
            <ScrollToTopButton />
        </>
    )
}

export default Home