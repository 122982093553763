import React from 'react'

function HighIntegrity() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <>
      {/* <iframe
        src="/pdfs/Corona-Filter.pdf"
        width="100%"
        height="2100px"
        style={{ border: 'none' }}
        title="Corona Filter PDF"
      /> */}
      <div style={{ width: '100%', height: '100vh' }}>
        {isMobile ? (
          <embed
            src="/pdfs/Corona-Filter.pdf"
            width="100%"
            height="100%"
            type="application/pdf"
          />
        ) : (
          <iframe
            src="/pdfs/Corona-Filter.pdf"
            width="100%"
            height="2100px"
            style={{ border: 'none' }}
            title="Corona Filter PDF"
          />
        )}
      </div>
    </>
  )
}

export default HighIntegrity